import jsPDF from "jspdf";
import {
  ContractSectionProps,
  GenInfoProps,
  RunningTotalsProps,
  SoftCostSectionProps,
  ForwardCommitmentSectionProps,
} from "../types";
import moment from "moment";

export type pdfProps = {
  brochurePrice: number;
  builderBonus: number;
  buyer: string;
  buyerSelectedOptions: number;
  cashOptionsPaid: number;
  closingCostBudget: number;
  costOfOptionsWithContract: number;
  customArchitecturalPlanChanges: number;
  dateSold: string;
  discretionary: number;
  exitStrategy: number;
  extraLaborActual: number;
  extraMaterialBudget: number;
  inHouseCommissions: number;
  interimInterestBudget: number;
  jobId: number;
  jobNumber: string;
  laborBudget: number;
  lotCost: number;
  lotExtras: number;
  lotPremium: number;
  materialBudget: number;
  optionalArchitecturalPlanChanges: number;
  optionsGiven: number;
  optionsRetailValue: number;
  planElevationNumber: string;
  planNumber: string;
  preparedBy: string;
  profitAnalysisId: number;
  profitAnalysisType: string;
  projectId: number;
  projectNumber: string;
  projectedCloseDate: string;
  realtorCommissions: number;
  realtorCommissionsadjusted: number;
  revision: number;
  salesAllowanceClosingCosts: number;
  salesAllowanceOptions: number;
  salesAllowancePriceReduced: number;
  salesPrice: number;
  specialMarketingIncentive: number;
  streetAddress: string;
  subdivision: string;
  specialLabor: number;
  specialMaterial: number;
};

export function generatePDF(
  pdfData: pdfProps,
  genInfo: GenInfoProps,
  contract: ContractSectionProps,
  labor: {
    LaborBudget: number | undefined;
    ExtraLaborActual: number | undefined;
    SpecialLabor: number | undefined;
  },
  material: {
    MaterialBudget: number | undefined;
    ExtraMaterialBudget: number | undefined;
    SpecialMaterial: number | undefined;
  },
  lotExtras: {
    LotExtras: number | undefined;
  },
  options: {
    CostOfOptionsWithContract: number | undefined;
  },
  softCost: SoftCostSectionProps,
  forwardCommitment: ForwardCommitmentSectionProps,
  totals: RunningTotalsProps
) {
  const doc = new jsPDF();

  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 10;
  const columnWidth = pageWidth / 2 - margin * 3 + 10;
  const lineHeight = 14; // Reduced line height for less vertical space

  // Function to add a centered title
  const addTitle = (title: string | string[]) => {
    let y = 10;
    doc.setFontSize(15); // Larger font size for the title
    doc.text(title, 105, y, { align: "center" }); // Center the title
    y += 3; // Increase y position after the title

    // Draw a line under the title
    doc.setDrawColor(0); // Set line color, change as needed
    doc.setLineWidth(0.2); // Set line width
    //doc.line(10, y, doc.internal.pageSize.width - 10, y); // Draw line from left to right margin

    y += 14; // Increase y position after the line
    doc.setFontSize(12); // Reset to original font size for the rest of the content
  };

    // Helper function to add Forward Commitment section fields
    const addForwardCommitmentField = (
        title: string,
        value: string | number | boolean | undefined
    ) => {
        const formattedValue =
            typeof value === "number" ? formatCurrency(value) : value;
        addTextField(contractSectionX, yPos, title, formattedValue as any);
        yPos += lineHeight; // Increment yPos for the next item
    };


  // Helper function to add text fields
  const addTextField = (
    x: number,
    y: number,
    title: string,
    value: string | number,
    fontSize?: number
  ) => {
    doc.setFontSize(10);
    doc.text(title, x, y, { maxWidth: columnWidth });
    doc.setFontSize(fontSize || 12);
    doc.text(`${value}`, x, y + 6, { maxWidth: columnWidth });
    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.line(x, y + 7, x + columnWidth, y + 7); // Adjust line placement as well
  };

  // Function to format date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // Vertical position start
  let yPos = 20;

  // Helper function to check if a new page is needed and add it
  const checkAndAddNewPage = () => {
    if (yPos > pageHeight - margin) {
      // Check if the current yPos exceeds page height
      doc.addPage();
      yPos = 20; // Reset yPos for the new page
    }
  };

  function percentage(partialValue: number, totalValue: number) {
    return (partialValue / totalValue) * 100;
  }

  const subtotalLaborMaterial =
    (totals.labor || 0) + (totals.material || 0) + (totals.lotExtras || 0);

  const totalLaborMaterial =
    (subtotalLaborMaterial || 0) + (totals.options || 0);

  const totalCost = (totalLaborMaterial || 0) + (totals.softCost || 0);

  const profitUSD = (totals.contract || 0) - (totalCost || 0);

  const profitMargin = percentage(profitUSD, totals.contract) || 0;

  const varianceToExitStrategy = profitMargin - (genInfo.ExitStrategy || 0);
  // Add the title
  addTitle("Profit Analysis");
  yPos += lineHeight - 5;

  // Start adding fields with reduced spacing
  addTextField(margin, yPos, "Subdivision", genInfo.Subdivision || "");
  addTextField(pageWidth / 2, yPos, "Address", genInfo.StreetAddress || "");

  yPos += lineHeight; // Increase y position by lineHeight
  addTextField(margin, yPos, "Job Number", genInfo.JobNumber || "");
  addTextField(
    pageWidth / 2,
    yPos,
    "Project Number",
    genInfo.ProjectNumber || ""
  );

  yPos += lineHeight;
  doc.setFontSize(10); // Decrease font size by 2 (assuming original size is 12)
  addTextField(margin, yPos, "Buyer", genInfo.Buyer || "", 10);
  doc.setFontSize(12); // Reset font size back to original
  const planAndElevation = `${genInfo.PlanNumber || ""} / ${
    genInfo.PlanElevationNumber || ""
  }`;
  addTextField(pageWidth / 2, yPos, "Plan / Elevation", planAndElevation || "");

  yPos += lineHeight;
  addTextField(margin, yPos, "PA Type", genInfo.ProfitAnalysisType);
  addTextField(
    pageWidth / 2,
    yPos,
    "Exit Strategy",
    `${genInfo.ExitStrategy}%`
  );

  yPos += lineHeight;
  addTextField(
    margin,
    yPos,
    "Date Sold",
    formatDate(genInfo.DateSold || "") || ""
  );
  addTextField(
    pageWidth / 2,
    yPos,
    "Projected Closed Date",
    formatDate(genInfo.ProjectedCloseDate?.toString() || "") || ""
  );

  // Position for the new section
  yPos = 100; // Adjust this value based on the last position from the previous section

  // Helper function to format currency
  const formatCurrency = (amount: number | undefined, isNegative?: boolean) =>
    amount !== undefined
      ? `$ ${isNegative ? "- " : ""}${amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : "$ 0";

  // Helper function to format currency
  const formatPercentage = (amount: number | undefined) =>
    amount !== undefined
      ? `${amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })} %`
      : "$ 0";

  // New x position for the right-aligned contract section
  const contractSectionX = pageWidth - columnWidth - margin * 2;
  yPos += lineHeight - 5;

  // Helper function to add contract section fields with special formatting for "Contract Price"
  const addContractField = (
    title: string,
    value: number | undefined,
    isTotal = false
  ) => {
    const formattedValue = formatCurrency(value);

    // Check if the field is "Contract Price" and set font to bold
    if (
      title === "Contract Price" ||
      title === "Total Cost" ||
      title === "Profit USD" ||
      title === "Profit Margin" ||
      title === "Variance To Exit Strategy"
    ) {
      doc.setFont("helvetica", "bold");
    }

    addTextField(
      isTotal ? contractSectionX + 1 : contractSectionX,
      yPos,
      title,
      formattedValue
    );

    // Reset font to normal after "Contract Price"
    if (
      title === "Contract Price" ||
      title === "Total Cost" ||
      title === "Profit USD" ||
      title === "Profit Margin" ||
      title === "Variance To Exit Strategy"
    ) {
      doc.setFont("helvetica", "normal");
    }

    yPos += lineHeight;
  };

  // Helper function to add contract section fields with special formatting for "Contract Price"
  const addNegativeContractField = (
    title: string,
    value: number | undefined,
    isTotal = false
  ) => {
    const formattedValue = formatCurrency(value);

    // Check if the field is "Contract Price" and set font to bold
    if (
      title === "Contract Price" ||
      title === "Total Cost" ||
      title === "Profit USD" ||
      title === "Profit Margin" ||
      title === "Variance To Exit Strategy"
    ) {
      doc.setFont("helvetica", "bold");
    }

    addTextField(
      isTotal ? contractSectionX + 1 : contractSectionX,
      yPos,
      title,
      `-${formattedValue}`
    );

    // Reset font to normal after "Contract Price"
    if (
      title === "Contract Price" ||
      title === "Total Cost" ||
      title === "Profit USD" ||
      title === "Profit Margin" ||
      title === "Variance To Exit Strategy"
    ) {
      doc.setFont("helvetica", "normal");
    }

    yPos += lineHeight;
  };

  const addPercentageField = (
    title: string,
    value: number | undefined,
    isTotal = false
  ) => {
    const formattedValue = formatPercentage(value);
    // Check if the field is "Contract Price" and set font to bold
    if (
      title === "Contract Price" ||
      title === "Total Cost" ||
      title === "Profit USD" ||
      title === "Profit Margin" ||
      title === "Variance To Exit Strategy"
    ) {
      doc.setFont("helvetica", "bold");
    }

    addTextField(
      isTotal ? contractSectionX + 1 : contractSectionX,
      yPos,
      title,
      formattedValue
    );

    // Reset font to normal after "Contract Price"
    if (
      title === "Contract Price" ||
      title === "Total Cost" ||
      title === "Profit USD" ||
      title === "Profit Margin" ||
      title === "Variance To Exit Strategy"
    ) {
      doc.setFont("helvetica", "normal");
    }

    yPos += lineHeight;
  };

  // Reset font to normal for other fields
  doc.setFont("helvetica", "normal");

  // Add a bold title for the contract section
  doc.setFontSize(12); // Set the title font size
  doc.setFont("helvetica", "bold"); // Set font to bold if available
  doc.text("Contract", contractSectionX, yPos);
  yPos += lineHeight; // Increment yPos to place the contract fields below the title

  // Reset font to normal for other fields
  doc.setFont("helvetica", "normal");

  // Add fields for the contract section
  addContractField("Brochure Price", contract.BrochurePrice || 0);
  addContractField("Lot Premium Price", contract.LotPremium || 0);
  addContractField(
    "Optional Architectural Plan Changes",
    contract.OptionalArchitecturalPlanChanges || 0
  );
  addContractField(
    "Custom Architectural Plan Changes",
    contract.CustomArchitecturalPlanChanges || 0
  );
  addContractField(
    "Buyer Selected Options/Spec Options",
    contract.BuyerSelectedOptions || 0
  );
  addContractField(
    "Options Given TBD/Seller Paid Closing Costs",
    contract.OptionsGiven || 0
  );
  addContractField(
    "Options Retail Value @ N/C - (Incl Spec Opts)",
    contract.OptionsRetailValue || 0
  );
  addContractField("Cash Options Paid", contract.CashOptionsPaid || 0);
  addNegativeContractField(
    "Sales Allowance Options",
    contract.SalesAllowanceOptions || 0
  );
  addNegativeContractField(
    "Sales Allowance Closing Costs",
    contract.SalesAllowanceClosingCosts || 0
  );
  addNegativeContractField(
    "Sales Allowance Price Reduced",
    contract.SalesAllowancePriceReduced || 0
  );
  addContractField("Contract Price", totals.contract || 0, true);

  // // Adjust yPos back for the last line since it doesn't need to be incremented after drawing the box
  yPos += 10;
  checkAndAddNewPage();

  // Add a bold title for the labor section
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Labor", contractSectionX, yPos);
  yPos += lineHeight; // Increment yPos to place the labor fields below the title

  // Reset font to normal for the subsequent fields
  doc.setFont("helvetica", "normal");

  // Helper function to add labor section fields
  const addLaborField = (title: string, value: number | undefined) => {
    addTextField(contractSectionX, yPos, title, formatCurrency(value));
    yPos += lineHeight; // Increment yPos for the next item
  };

  // Add fields for the labor section
  addLaborField("Labor Budget/Category A", labor.LaborBudget || 0);
  addLaborField("Extra Labor Actual/Category B", labor.ExtraLaborActual || 0);
  addLaborField("Special Labor Budget", labor.SpecialLabor || 0);

  // Special formatting for the total labor value
  const totalLaborValue = formatCurrency(totals.labor || 0);
  doc.setFont("helvetica", "bold");
  addTextField(contractSectionX + 1, yPos, "Total Labor", totalLaborValue || 0);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(12); // Set the value font size for the total
  doc.setLineWidth(0.2);
  // doc.rect(rectX, rectY, rectWidth, rectHeight);
  yPos += lineHeight; // Adjust yPos for the space below the rectangle
  checkAndAddNewPage();

  yPos += lineHeight;

  // Add a bold title for the material section
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Material", contractSectionX, yPos);
  yPos += lineHeight; // Increment yPos to place the material fields below the title

  // Reset font to normal for the subsequent fields
  doc.setFont("helvetica", "normal");

  // Helper function to add labor section fields
  const addMaterialField = (title: string, value: number | undefined) => {
    addTextField(contractSectionX, yPos, title, formatCurrency(value));
    yPos += lineHeight; // Increment yPos for the next item
  };

  // Add fields for the material section
  addMaterialField("Material Budget/Category C", material.MaterialBudget || 0);
  addMaterialField(
    "Extra Material Budget/Category D",
    material.ExtraMaterialBudget || 0
  );
  addMaterialField("Special Material Budget", material.SpecialMaterial || 0);

  // Special formatting for the total labor value
  const totalMaterialValue = formatCurrency(totals.material || 0);
  doc.setFont("helvetica", "bold");
  addTextField(
    contractSectionX + 1,
    yPos,
    "Total Material",
    totalMaterialValue
  );
  doc.setFont("helvetica", "normal");
  // Draw a rectangle around the total labor value
  doc.setFontSize(12); // Set the value font size for the total
  doc.setLineWidth(0.2);
  //doc.rect(rectXM, rectYM, rectWidthM, rectHeightM);
  yPos += lineHeight; // Adjust yPos for the space below the rectangle
  checkAndAddNewPage();

  yPos += lineHeight;

  // Add a bold title for the lot extras section
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Lot Extras(Exit Strategy)", contractSectionX, yPos);
  yPos += lineHeight; // Increment yPos to place the lot extras fields below the title

  // Reset font to normal for the subsequent fields
  doc.setFont("helvetica", "normal");

  // Helper function to add labor section fields
  const addLotExtrasField = (title: string, value: number | undefined) => {
    addTextField(contractSectionX, yPos, title, formatCurrency(value));
    yPos += lineHeight; // Increment yPos for the next item
  };

  // Add fields for the lot extras section
  addLotExtrasField("Lot Extras(Exit Strategy)", lotExtras.LotExtras || 0);

  // Special formatting for the total labor value
  const totalLotExtrasValue = formatCurrency(totals.lotExtras || 0);
  doc.setFont("helvetica", "bold");
  addTextField(
    contractSectionX + 1,
    yPos,
    "Total Lot Extras(Exit Strategy)",
    totalLotExtrasValue
  );
  doc.setFont("helvetica", "normal");
  // Draw a rectangle around the total labor value
  doc.setFontSize(12); // Set the value font size for the total
  doc.setLineWidth(0.2);
  //doc.rect(rectXL, rectYL, rectWidthL, rectHeightL);
  yPos += lineHeight; // Adjust yPos for the space below the rectangle
  checkAndAddNewPage();

  doc.setFont("helvetica", "bold");
  // Special formatting for the total labor value
  const totalLaborMaterialValue = formatCurrency(subtotalLaborMaterial || 0);
  addTextField(
    contractSectionX + 1,
    yPos,
    "Subtotal Labor & Material",
    totalLaborMaterialValue
  );
  doc.setFont("helvetica", "normal");
  // Draw a rectangle around the total labor value
  doc.setFontSize(12); // Set the value font size for the total
  doc.setLineWidth(0.2);
  //doc.rect(rectXL2, rectYL2, rectWidthL2, rectHeightL2);
  yPos += lineHeight; // Adjust yPos for the space below the rectangle
  checkAndAddNewPage();

  yPos += lineHeight;

  // Add a bold title for the options section
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Options", contractSectionX, yPos);
  yPos += lineHeight; // Increment yPos to place the options fields below the title

  // Reset font to normal for the subsequent fields
  doc.setFont("helvetica", "normal");

  // Helper function to add options section fields
  const addOptionsField = (title: string, value: number | undefined) => {
    addTextField(contractSectionX, yPos, title, formatCurrency(value));
    yPos += lineHeight; // Increment yPos for the next item
  };

  // Add fields for the options section
  addOptionsField(
    "Cost of Options with Contract",
    options.CostOfOptionsWithContract || 0
  );

  doc.setFont("helvetica", "bold");
  // Special formatting for the total labor value
  const totalOptionsValue = formatCurrency(totals.options);
  addTextField(
    contractSectionX + 1,
    yPos,
    "Cost of Total Options",
    totalOptionsValue
  );
  doc.setFont("helvetica", "normal");
  // Draw a rectangle around the total labor value
  doc.setFontSize(12); // Set the value font size for the total
  doc.setLineWidth(0.2);
  //doc.rect(rectXO, rectYO, rectWidthO, rectHeightO);
  yPos += lineHeight; // Adjust yPos for the space below the rectangle
  checkAndAddNewPage();

  doc.setFont("helvetica", "bold");
  // Special formatting for the total labor value
  const totalLaborAndMaterialValue = formatCurrency(totalLaborMaterial || 0);
  addTextField(
    contractSectionX + 1,
    yPos,
    "Total Labor & Materials",
    totalLaborAndMaterialValue
  );
  doc.setFont("helvetica", "normal");
  // Draw a rectangle around the total labor value
  doc.setFontSize(12); // Set the value font size for the total
  doc.setLineWidth(0.2);
  // doc.rect(rectXLM, rectYLM, rectWidthLM, rectHeightLM);
  yPos += lineHeight; // Adjust yPos for the space below the rectangle
  checkAndAddNewPage();

  yPos += lineHeight;

  checkAndAddNewPage();

  // Add a bold title for the Soft Cost section
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text("Soft Cost", contractSectionX, yPos);
  yPos += lineHeight; // Increment yPos to place the soft cost fields below the title

  checkAndAddNewPage();

  // Reset font to normal for other fields
  doc.setFont("helvetica", "normal");

  // Helper function to add soft cost section fields
  const addSoftCostField = (
    title: string,
    value: string | number | boolean | undefined
  ) => {
    const formattedValue =
      typeof value === "number" ? formatCurrency(value) : value;
    addTextField(contractSectionX, yPos, title, formattedValue as any);
    yPos += lineHeight; // Increment yPos for the next item
  };

  // Helper function to add soft cost section fields
  const addNegativeSoftCostField = (
    title: string,
    value: string | number | undefined
  ) => {
    const formattedValue =
      typeof value === "number" ? formatCurrency(value) : value;
    addTextField(contractSectionX, yPos, title, `-${formattedValue}` as any);
    yPos += lineHeight; // Increment yPos for the next item
  };

  // Add fields for the soft cost section
  addSoftCostField(
    "Lot Cost/(Code F)(Note 2)(+/1 Deviation)",
    softCost.LotCost || 0
  );
  addSoftCostField(
    "Discretionary - Buyer Closing Costs paid by Seller",
    softCost.Discretionary || 0
  );
  addSoftCostField("Has Realtor", softCost.HasRealtor ? "Yes" : "No");
  addSoftCostField(
    "Realtor Commissions-(3%)/(Category H)",
    softCost.RealtorCommissions || 0
  );
  addSoftCostField(
    "Special Marketing Incentive",
    softCost.SpecialMarketingIncentive || 0
  );
  addNegativeSoftCostField(
    "Realtor Commissions Adjusted",
    softCost.RealtorCommissionsadjusted || 0
  );
  addSoftCostField(
    "In-House Commissions-(2%)/(Category I)",
    softCost.InHouseCommissions || 0
  );
  addSoftCostField(
    "Interim Interest Budget(#8140)",
    softCost.InterimInterestBudget || 0
  );
  addSoftCostField(
    "Closing Cost Budget/(Category K)",
    softCost.ClosingCostBudget || 0
  );
  addSoftCostField("Builder Bonus (#8195)", softCost.BuilderBonus || 0);

  doc.setFont("helvetica", "bold");
  // Special formatting for the total soft cost value
  const totalSoftCostValue = formatCurrency(totals.softCost || 0);
  addTextField(
    contractSectionX + 1,
    yPos,
    "Subtotal Soft Cost",
    totalSoftCostValue
    );

    yPos += lineHeight;
    yPos += lineHeight;
    checkAndAddNewPage();

    // Add a bold title for the Soft Cost section
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Forward Commitment", contractSectionX, yPos);
    yPos += lineHeight; // Increment yPos to place the soft cost fields below the title

    checkAndAddNewPage();

    // Reset font to normal for other fields
    doc.setFont("helvetica", "normal");

    // Add fields for the Forward Commitment section
    addForwardCommitmentField(
        "Has Forward Commitment",
        forwardCommitment.HasForwardCommitment ? "Yes" : "No"
    );

    addForwardCommitmentField(
        "Forward Commitment Expiry Date",
        forwardCommitment.ForwardCommitmentExpiryDate
            ? moment(forwardCommitment.ForwardCommitmentExpiryDate).format("YYYY-MM-DD")
            : "N/A" // Handle null or undefined values
    );

    addForwardCommitmentField(
        "Forward Commitment Batch Number",
        forwardCommitment.ForwardCommitmentBatchNumber || "N/A" // Handle empty string or undefined
    );

    checkAndAddNewPage();

    doc.setFont("helvetica", "normal");
  // Draw a rectangle around the total soft cost value
  doc.setFontSize(12); // Set the value font size for the total
  doc.setLineWidth(0.2);
  // doc.rect(rectXSC, rectYSC, rectWidthSC, rectHeightSC);
  yPos += lineHeight; // Adjust yPos for the space below the rectangle
  checkAndAddNewPage();
  yPos += lineHeight;

  // Add a bold title for the contract section
  doc.setFontSize(12); // Set the title font size
  doc.setFont("helvetica", "bold"); // Set font to bold if available
  doc.text("Summary", contractSectionX, yPos);
  yPos += lineHeight; // Increment yPos to place the contract fields below the title
  addContractField("Contract Price", totals.contract || 0);
  addContractField("Total Cost", totalCost || 0);
  addContractField("Profit USD", profitUSD || 0);
  addPercentageField("Profit Margin", profitMargin || 0);
  checkAndAddNewPage();
  addPercentageField("Variance To Exit Strategy", varianceToExitStrategy || 0);

  yPos += lineHeight;
  checkAndAddNewPage();
  // Save the PDF
  doc.save(`${genInfo.StreetAddress}`);
}